
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            ready: false,
            markerFill: {
                enabled: true,
                color: 'black',
                opacity: 1,
                // imageHref
            },
            markerIcon: {
                layout: 'default#image', // 'default#imageWithContent' для использования с контентом
                imageHref: require('~/assets/icons/icon-map.svg'), // адрес изображения или data:image/svg+xml;base64
                imageSize: [43, 55], // размер иконки в px
                imageOffset: [-22, -55], // смещение иконки в px,
                /* Следующие поля актуальны для layout: 'default#imageWithContent' */
                content: 'some content here', // содержимое контента
                contentOffset: [-22, -55], // смещение контента в px,
                contentLayout: '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>' // строковый HTML шаблон для контента
            }
        }
    },
    methods: {
        ...mapActions({
            // liked: 'favorite/favorite/liked',
            openModal: 'modal/modal-main/openModal',
        }),
        async callback() {
            let payload = {
                modal_component: 'modal-callback-main',
            }
            await this.openModal(payload)
        },
    },
    computed: {
        ...mapGetters({
            settings: 'settings/settings/settings',
			isNight: "settings/settings/isNight"

        }),
        mapstyle() {
            return !this.$device.isMobile ? { width: '100%', height: '100%' } : { width: '100%', height: '100%', filter: 'grayscale(100%)' }
        },
        coordinates() {
            return this.$device.isMobile ? ['55.623380', ' 37.618228'] : this.settings.coordinates.split(',')
        },
        timeForCallback() {
            return new Date().getHours() >= 21
        }

    },
    mounted() {
      document.addEventListener("DOMContentLoaded", function() {
        if (!this.$device.isMobile) {
          const leftpadding = document.getElementsByClassName('desc head2 bold red--text line')[0].getBoundingClientRect().left
          this.$refs.map__leftSide.animate({
            paddingLeft: `20px`,
            opacity: 1
          }, {
            duration: 1000, fill: 'forwards', easing: 'ease-in-out'
          })
        } else {
          this.$refs.map__leftSide.animate({
            opacity: 1
          }, {
            duration: 1000, fill: 'forwards', easing: 'ease-in-out'
          })
        }
      })
      setTimeout(() => {
        this.ready = true
      }, 500)
    },

}
