
import { mapActions, mapMutations } from "vuex";
import formValidation from "@/mixins/formValidation";

export default {
  mixins: [formValidation],
  props: {
    modal: Boolean,
    testDrive: Boolean
  },
  methods: {
    ...mapMutations({
      setModalCount: "modal/modal-main/SET_OPEN_MODAL_COUNT"
    }),
    ...mapActions({
      sendForm: "form/form/sendForm",
      closeModal: "modal/modal-main/closeModal"
    }),
    checkForm() {
      if (!this.form.phone.valid) {
        this.form.phone.valid = false;
        return false;
      }
      if (!this.form.agreeRf) {
        this.error = "agreeRf";
        return false;
      }
      if (!this.form.agree) {
        this.error = "agree";
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.checkForm()) {
        let formData = {
          type: "callback",
          client_phone: this.form.phone.value
        };
        // utm
        if (localStorage.utm_source) {
          formData.utm_source = localStorage.utm_source;
        }
        if (localStorage.utm_medium) {
          formData.utm_medium = localStorage.utm_medium;
        }
        if (localStorage.utm_campaign) {
          formData.utm_campaign = localStorage.utm_campaign;
        }
        if (localStorage.utm_term) {
          formData.utm_term = localStorage.utm_term;
        }
        if (localStorage.utm_content) {
          formData.utm_content = localStorage.utm_content;
        }
        await this.setModalCount(1);
        await this.sendForm(formData);
        await this.closeModal();
      }
    }
  }
};
