import { render, staticRenderFns } from "./form-test-drive.vue?vue&type=template&id=634b2b00&"
import script from "./form-test-drive.vue?vue&type=script&lang=js&"
export * from "./form-test-drive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormCallbackPhone: require('/home/d/didisher2/si-auto.ru/www/components/form/form-callback-phone.vue').default})
