
import FEEDBACK from '~/apollo/mutations/feedback'
import { mapGetters } from "vuex";
import changeCheckbox from "../../mixins/formValidation";
import FormCallbackPhone from "./form-callback-phone.vue";

export default {
  components: {FormCallbackPhone},
  mixins:[changeCheckbox],
  props: {
    data: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      name: '',
      phone: '',
      error: '',
      monthPay: '0',
      checkPrivacy: true,
      callbackOption: null,
    }
  },
  computed: {
    ...mapGetters({
      site_id: 'site_id',
      settings: 'settings/settings/settings'
    }),
  },
  methods: {
    chooseCallbackOption(option) {
      this.callbackOption = option;
    },
    getCallbackOptions() {
      const hours = [];
      let currentHours = new Date().getHours();
      let isNight = false;
      if (currentHours < 9 || currentHours >= 21) {
        currentHours = 9;
        isNight = true;
      }
      const initialDate = new Date();
      initialDate.setHours(currentHours);
      while (currentHours < 21) {
        if (currentHours === initialDate.getHours() && initialDate.getMinutes() >= 50) {
          currentHours++;
          continue;
        }
        hours.push({
          title: `с ${currentHours}:00 до ${++currentHours}:00`,
        });
      }
      return isNight ? [...hours] : [{ title: 'Как можно скорее' }, ...hours];
    },
    changeCheckPrivacy(state) {
      this.checkPrivacy = !state
    },
    checkForm() {
      if (!this.name) {
        this.error = 'name';
        return false;
      }
      if (!this.phone || this.phone.split('_').length !== 1) {
        this.error = 'phone';
        return false;
      }
      if (!this.checkPrivacy) {
        return false;
      }
      return true;
    },
    async sendCallBackForm() {
      if (this.checkForm()) {
        this.$emit('sendForm')
        let formData = {
          site_id: this.site_id,
          type: 'TEST-DRIVE',
          client_name: this.name,
          client_phone: this.phone,
          utm_source: localStorage.utm_source || '',
          utm_medium: localStorage.utm_medium || '',
          utm_campaign: localStorage.utm_campaign || '',
          utm_term: localStorage.utm_term || '',
          utm_content: localStorage.utm_content || '',
          // comment: this.callbackOption?.title ? `Когда Вам перезвонить? ${this.callbackOption.title}` : null,
        }
        try {
          const res = await this.$apollo.mutate({
            mutation: FEEDBACK,
            variables: formData
          }).then((response) => {
            let onlyMetrik = {
              eventName: 'thanks',
            }
            if (this.$store.state.chosenCar) {
              let metrikParams = {
                eventName: "thanks",
                ecommerceId: response.data.feedback.id,
                ecommerceProductsId: this.$store.state.chosenCar.external_id,
                ecommerceProductsName: `${this.$store.state.chosenCar.mark.title} ${this.$store.state.chosenCar.folder.title}, ${this.$store.state.chosenCar.year} года, ${this.$store.state.chosenCar.gearbox.title}, ${this.$store.state.chosenCar.price} руб. - ${this.$store.state.chosenCar.external_id}`,
                ecommerceProductsPrice: this.$store.state.chosenCar.price,
                ecommerceProductsBrand: this.$store.state.chosenCar.mark
                    .title,
                ecommerceProductsCategory: `С пробегом/${this.$store.state.chosenCar.mark.title}/${this.$store.state.chosenCar.folder.title}/${this.$store.state.chosenCar.name}`,
                ecommerceProductsQuantity: "1"
              };

              this.$router.push({ name: "thanks", params: { metrikParams } });
            } else {
              this.$router.push({ name: 'thanks', params: { onlyMetrik } });
            }
            this.$store.commit('hideModal')
          });
        }
        catch (e) {
          console.error(e)
        }
      }
    },
  }
}
