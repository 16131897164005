
import { mapActions, mapGetters, mapMutations } from "vuex";
import declension from "@/mixins/declension";

export default {
	mixins: [declension],
	data() {
		return {
			routerQueryTags: [],
			isActive: false,
			stateScroll: true,
			sortList: {
				'price|asc': 'Цена: Сначала дешевле',
				'price|desc': 'Цена: Сначала дороже',
				'run|asc': 'Пробег: Минимальный',
				'year|desc': 'Год: Сначала новее'
			},
			tags: []
		}
	},
	props: {
		modal: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			filteredTotal: 'filters/filters/filteredTotal',
			sortModal: 'modal/modal-choose/sort', // СОРТИРОВКА ДЛЯ КАТАЛОГА
			sort: 'filters/filters/sort', // СОРТИРОВКА ДЛЯ ФИЛЬТРОВ
			viewModal: 'modal/modal-choose/view', // ОТОБРАЖЕНИЕ ДЛЯ КАТАЛОГА
			viewFilter: 'filters/filters/view', // ОТОБРАЖЕНИЕ ДЛЯ ФИЛЬТРОВ

			chosen: 'filters/filters/chosen',

			filters: 'filters/filters/filters',
			offers: 'filters/filters/offers',

		}),

		chosenArray() {
			const arr = []
			for (let item in this.chosen) {
				if (this.chosen[item] !== null && item !== '__typename') {
					this.chosen[item].map(x => x.type = item)
					arr.push(...this.chosen[item])
				}
			}
			return arr
		},
		currentCarsCount() {
			return this.filteredTotal + ' ' + this.declension({
				count: this.filteredTotal,
				one: 'автомобиль',
				few: 'автомобиля',
				many: 'автомобилей'
			}) + ' в наличии'
		},
		currentIcon() {
			if (this.sort.split('|')[1] === 'asc') {
				return 'icon-sort'
			} else {
				return 'icon-sort-alt'
			}
		},
		currentSort() {
			if (this.modal) {
				return this.sortList[this.sortModal]
			} else {
				return this.sortList[this.sort]
			}
		},
		// routerQueryTags() {
		// 	const subArr = []
		// 	this.chosen && Object.entries(this.chosen).filter(x => x[1] !== null
		// 		&& x[0] !== '__typename' && x[0] !== "yearFrom" && x[0] !== "yearTo" && x[0] !== "priceFrom" && x[0] !== "priceTo")
		// 		.forEach(item => Array.isArray(item[1])
		// 			? subArr.push(...item[1].map(x => {
		// 				x.type = item[0]
		// 				return x
		// 			}))
		// 			: subArr.push(item[1]))
		// 	return subArr
		// }

		routePath() {
			return this.$route.path
		}
	},
	mounted() {
		if (this.$route.query.sort && !this.modal) {
			this.sortChosen(this.$route.query.sort)
		} else {
			// !this.$route.params.car && this.sortChosen('price|asc')
		}
		this.changeTags()
	},
	methods: {

		...mapActions({
			getOffers: 'modal/modal-choose/getOffers',
			openModal: 'modal/modal-main/openModal',
			setUrl: 'filters/filters/setUrl',

		}),
		...mapMutations({
			setSort: 'filters/filters/SET_SORT', //СОРТИРВОКА ДЛЯ ФИЛЬТРОВ
			setModalSort: 'modal/modal-choose/SET_MODAL_SORT',//СОРТИРВОКА ДЛЯ МОДАЛКИ КАТОАЛОГА
			setViewCatalog: 'modal/modal-choose/SET_VIEW',//ОТОБРАЖЕНИЕ ДЛЯ МОДАЛКИ
			setViewFilters: 'filters/filters/SET_VIEW',//ОТОБРАЖЕНИЕ ДЛЯ ФИЛЬТРОВ
			setLoadingFilters: 'filters/filters/SET_LOADING',
			setLoadingCatalog: 'modal/modal-choose/SET_LOADING',
			setFilterClick: 'filters/filters/SET_IS_FILTER_CLICK',
			unsetChosen: 'filters/filters/UNSET_CHOSEN',
			setIsFilterClick: 'filters/filters/SET_IS_FILTER_CLICK',
			setChosen: 'filters/filters/SET_CHOSEN',

		}),
		changeTags() {
			let subArr = []
			this.chosen && Object.entries(this.chosen).filter(x => x[1] !== null
				&& x[0] !== '__typename' && x[0] !== "yearFrom" && x[0] !== "yearTo" && x[0] !== "priceFrom" && x[0] !== "priceTo")
				.forEach(item => Array.isArray(item[1])
					? subArr.push(...item[1].map(x => {
						x.type = item[0]
						return x
					}))
					: subArr.push(item[1]))
			this.routerQueryTags = subArr
		},
		async deleteTag(tag, value) {
			this.setIsFilterClick(true)
			this.setChosen({ key: tag, value: this.chosen[tag].filter(x => x.id !== value.id) })
			// await this.$router.push({ path: this.$route.fullPath, query: { tag: this.$route.query[key].filter(x=>x!==value.slug)  } });
			switch (tag) {
				case 'mark':
					this.setChosen({ key: 'folder', value: null })
					this.setChosen({ key: 'generation', value: null })
					this.setChosen({ key: 'bodyType', value: null })
					this.setChosen({ key: 'engineType', value: null })
					this.setChosen({ key: 'gearbox', value: null })
					this.setChosen({ key: 'priceFrom', value: null })
					this.setChosen({ key: 'priceTo', value: null })
					this.setChosen({ key: 'yearFrom', value: null })
					this.setChosen({ key: 'yearTo', value: null })
					break;
				case 'folder':
					this.setChosen({ key: 'generation', value: null })
					this.setChosen({ key: 'bodyType', value: null })
					this.setChosen({ key: 'engineType', value: null })
					this.setChosen({ key: 'gearbox', value: null })
					this.setChosen({ key: 'priceFrom', value: null })
					this.setChosen({ key: 'priceTo', value: null })
					this.setChosen({ key: 'yearFrom', value: null })
					this.setChosen({ key: 'yearTo', value: null })
					break;

				case 'generation':
					this.setChosen({ key: 'bodyType', value: null })
					this.setChosen({ key: 'engineType', value: null })
					this.setChosen({ key: 'gearbox', value: null })
					this.setChosen({ key: 'priceFrom', value: null })
					this.setChosen({ key: 'priceTo', value: null })
					this.setChosen({ key: 'yearFrom', value: null })
					this.setChosen({ key: 'yearTo', value: null })
					break;

				default:
					break;
			}
			this.setUrl()
		},
		async clickToChosen(event) {
			// this.unsetChosen(event)
			this.setIsFilterClick(true)
			await this.$router.push({ path: this.$route.fullPath, query: { event: null } });
		},
		async changeView(type) {
			if (this.modal) {
				await this.setLoadingCatalog(true)
				await this.setViewCatalog(type)
				setTimeout(() => {
					this.setLoadingCatalog(false)
				}, 300)
			} else {
				await this.setLoadingFilters(true)
				await this.setViewFilters(type)
				setTimeout(() => {
					this.setLoadingFilters(false)
				}, 300)
			}
		},
		openFilter() {
			let payload = {
				modal_component: 'modal-filter-mobile',
				modal_data: 'filter-mobile',
				modal_title: 'Фильтр',
				modal_sub_title: this.currentCarsCount
			}
			this.openModal(payload)
		},
		async sortChosen(sort, value) {
			this.setFilterClick(true)
			if (sort === 'mobile') {
				if (this.modal) {
					this.setModalSort(value)
					await this.getOffers()
				} else {
					let query = { sort: value }
					if (Number(this.$route.query.page) > 1) {
						query.page = 1
					}
					this.setSort(value)
					await this.$router.push({ path: this.$route.fullPath, query: query });
				}
			} else {
				if (this.modal) {
					this.setModalSort(sort)
					await this.getOffers()
				} else {
					let query = { sort: sort }
					if (Number(this.$route.query.page) > 1) {
						query.page = 1
					}
					this.setSort(sort)
					await this.$router.push({ path: this.$route.fullPath, query: query });
				}
			}

		}
	},
	watch: {
		offers() {
			this.changeTags()
		}
	}
}
