import { render, staticRenderFns } from "./index.vue?vue&type=template&id=39dde0be&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderIndexMainNew: require('/home/d/didisher2/si-auto.ru/www/components/slider-index/main-new.vue').default,CatalogUsed: require('/home/d/didisher2/si-auto.ru/www/components/catalog/catalog-used.vue').default,FormTestDrive: require('/home/d/didisher2/si-auto.ru/www/components/form/form-test-drive.vue').default,Programs: require('/home/d/didisher2/si-auto.ru/www/components/programs/index.vue').default,AdvantagesMain: require('/home/d/didisher2/si-auto.ru/www/components/advantages/advantages-main.vue').default,Banks: require('/home/d/didisher2/si-auto.ru/www/components/Banks/index.vue').default,Map: require('/home/d/didisher2/si-auto.ru/www/components/map/index.vue').default})
