
import AOS from 'aos'
import 'aos/dist/aos.css'
import {mapGetters} from "vuex";
import 'swiper/modules/pagination/pagination.scss'

export default {
  mounted() {
    AOS.init({
      once: true
    })
    const sliderIndexSwiper = new swiper.default('.slider-index .swiper', {
      modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: true,
      slidesPerView: 1,
      // autoHeight: true,
      watchSlidesProgress: true,
      scrollbar: false,
      speed: 1800,
      autoplay: {
        delay: 8000,
      },
      on: {
        sliderMove: this.sliderMove,
        slideChangeTransitionStart: this.slideChangeTransitionStart
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: 'true',
        type: 'bullets',
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + '0' + (index + 1) + '</span>';
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
    sliderMove() {
      document.querySelectorAll('.swiper-slide-next .slider-index__media').forEach((val) => {
      })
      document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
        val.style.display = 'none'
        val.classList.remove("aos-init", "aos-animate");
        val.style.display = 'block'
        val.classList.add("aos-init", "aos-animate");
      })
    },
    slideChangeTransitionStart() {
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings'
    }),
    slides() {
      return [
        {
          id: 10,
          heading: 'АВТОКРЕДИТ',
          content: `СТАВКА ОТ 4.9%`,
          link: '/credit',
          image: require('~/assets/img/main_new/slide1.webp'),
          image_mob: require('~/assets/img/main_new/slide1@2x-mobile.webp'),
        },
        {
          id: 6,
          heading: `TRADE-IN`,
          content: `ГАРАНТИЯ 2 ГОДА`,
          link: '/exchange',
          image: require('~/assets/img/main_new/slide2.webp'),
          image_mob: require('~/assets/img/main_new/slide2@2x-mobile.webp'),
        },

        {
          id: 8,
          heading: 'ВЫКУП АВТО',
          content: `Продайте машину быстро и безопасно`,
          link: '/vykup',
          image: require('~/assets/img/main_new/slide3.webp'),
          image_mob: require('~/assets/img/main_new/slide3@2x-mobile.webp'),
        },
        {
          id: 1,
          heading: 'БАНКИ-ПАРТНЕРЫ',
          content: `Специальные условия для клиентов СИБАВТОЮГ`,
          link: '/vykup',
          image: require('~/assets/img/main_new/slide4.webp'),
          image_mob: require('~/assets/img/main_new/slide4@2x-mobile.webp'),
        },
      ]
    }
  }
}
