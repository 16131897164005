
export default {
	props: {
		offers: Object,
		activeButton: Number
	},
	computed: {
		currentPagination: {
			get() {
				return Number(this.$route.query.page || this.activeButton || 1)
			},
			set(pageNum) {
				this.$router.push({query: {page: pageNum}});
			}
		}
	},
	methods: {
		paginationCLick(pageNum) {
			setTimeout(function () {window.scrollTo(0, -100);}, 1);
			this.$router.push({path: this.$route.fullPath, query: {page: pageNum}});
		},
		arrowClick(type) {
			if (this.$route.query.page) {
				let pageNum = Number(this.$route.query.page)
				if (type === 'next') {
					pageNum++
				} else if (type === 'prev' && pageNum !== 1) {
					pageNum--
				}
				this.$router.push({path: this.$route.fullPath, query: {page: '' + pageNum}});
				setTimeout(function () {window.scrollTo(0, -100);}, 1);
			} else {
				this.$router.push({path: this.$route.fullPath, query: {page: '2'}});
			}
		}
	}
}
