import { render, staticRenderFns } from "./catalog-list-used-desktop.vue?vue&type=template&id=542939f6&"
import script from "./catalog-list-used-desktop.vue?vue&type=script&lang=js&"
export * from "./catalog-list-used-desktop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonTypical: require('/home/d/didisher2/si-auto.ru/www/components/button/button-typical.vue').default,Pagination: require('/home/d/didisher2/si-auto.ru/www/components/pagination/pagination.vue').default})
