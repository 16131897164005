

import CatalogListUsedDesktop from "../components/catalog/catalog-list/catalog-list-used-desktop.vue";
import Map from "../components/map/contacts-map.vue"
import AdvantagesMain from "../components/advantages/advantages-main.vue";
import CatalogUsed from "../components/catalog/catalog-used.vue";
import seoTags from "~/mixins/seoTags";
export default {
  components: {CatalogUsed, AdvantagesMain, CatalogListUsedDesktop, Map},
	mixins: [seoTags],
	mounted() {
		setTimeout(function () {
			window.scrollTo(0, -100);
		}, 1);
	},
}
