
import {mapActions} from "vuex";

export default {
  data(){
    return{
      programs:[
        {
          img: require('~/assets/img/programs/credit@2x.webp'),
          title: 'ВЫГОДНЫЙ КРЕДИТ',
          modal_title: 'Рассчитать кредит с максимальной выгодой',
          description: 'Оставьте заявку на кредит и получите одобрение за 1 день!',
          button: 'РАССЧИТАТЬ КРЕДИТ'
        },
        {
          img: require('~/assets/img/programs/tradeIn@2x.webp'),
          title: 'ПРОГРАММА TRADE-IN',
          modal_title: 'Заполните заявку на TRADE-IN',
          description: 'Обменяйте свой старый автомобиль на новый',
          button: 'ОСТАВИТЬ ЗАЯВКУ'
        }
      ]
    }
  },
  methods:{
    ...mapActions({
      modal: "modal/modal-main/openModal",
    }),
    openModal(item){
      let payload = {
        modal_component: 'modal-program',
        modal_title: item.modal_title,
        modal_sub_title: '',
        modal_data: item
      }
      this.modal(payload)
    }
  }
}

