
import capitalizeFirstLetter from "~/mixins/capitalizeFirstLetter";
import benefits from '../benefits/benefits.vue';
import FEEDBACK from "~/apollo/mutations/feedback";
import {mapActions} from "vuex";
import CatalogListIndex from "./catalog-list/catalog-list-index.vue";

export default {
	components: {CatalogListIndex, benefits },
  data(){
    return{
      isSelectExpanded: false,
      comment: null,
      error: '',
      wannaCredit: true,
      wannaTradein: true,
      phone: '',
      name: '',
      isVisible:{
        time:false
      },
      catalogActive: false,
      callbackOption: null,
      timeArray: [
        'с 9:00 до 12:00',
        'с 12:00 до 17:00',
        'c 17:00 до 21:00'
      ]
    }
  },
	mixins: [capitalizeFirstLetter],
	props: {
		pageTitle: String,
		offers: {
			type: Boolean,
			default: false,
		},
	},
  methods:{
    ...mapActions({
      openModal: 'modal/modal-main/openModal',
      sendForm: 'form/form/sendForm',
      closeModal: 'modal/modal-main/closeModal'
    }),
    exposeSelectModal(title, payload) {
      this.openModal( {modal_component: 'form-select',modal_title: title, modal_sub_title: payload.modal_sub_title, modal_data: payload})
    },
    async sendCallBackForm() {
      if (this.checkForm()) {
        let formData = {
          type: 'free-selection',
          client_name: this.name,
          client_phone: this.phone,
          comment: this.callbackOption?.title ? `Перезвонить: ${this.callbackOption.title}. Комментарий: ${this.description ? this.description : 'нет'}. Кредит: ${this.credit ? 'да' : 'нет'}. Trade-In: ${this.trade_in ? 'да' : 'нет'}`: null
        }

        // utm
        if (localStorage.utm_source) {
          formData.utm_source = localStorage.utm_source
        }
        if (localStorage.utm_medium) {
          formData.utm_medium = localStorage.utm_medium
        }
        if (localStorage.utm_campaign) {
          formData.utm_campaign = localStorage.utm_campaign
        }
        if (localStorage.utm_term) {
          formData.utm_term = localStorage.utm_term
        }
        if (localStorage.utm_content) {
          formData.utm_content = localStorage.utm_content
        }
        await this.sendForm(formData)
        await this.closeModal()
      }
    },
    checkForm() {
      if (!this.name) {
        this.error = 'name';
        return false;
      }
      if (!this.phone || this.phone.includes('_')) {
        this.error = 'phone';
        return false;
      }
      return true;
    },
    chooseCallbackOption(option) {
      this.callbackOption = option;
    },
    getCallbackOptions() {
      const hours = [];
      let currentHours = new Date().getHours();
      let isNight = false;
      if (currentHours < 9 || currentHours >= 21) {
        currentHours = 9;
        isNight = true;
      }
      const initialDate = new Date();
      initialDate.setHours(currentHours);
      while (currentHours < 21) {
        if (currentHours === initialDate.getHours() && initialDate.getMinutes() >= 50) {
          currentHours++;
          continue;
        }
        hours.push({
          title: `с ${currentHours}:00 до ${++currentHours}:00`,
        });
      }
      return isNight ? [...hours] : ['Как можно скорее', ...hours];
    },
  }
};
