
import {mapActions, mapGetters} from "vuex";
import settings from "../../mixins/settings";
import FormCallbackPhone from "../form/form-callback-phone.vue";

export default {
  components: {FormCallbackPhone},
  props:['credit'],
  computed: {
    settings() {
      return settings
    },
    ...mapGetters({
      'advantages_main': "advantages/advantages_main",
      'advantages_credit': "advantages/advantages_credit",
      'settings': 'settings/settings/settings'
    }),
    advantages() {
      return this.advantages_main
    }
  },
  methods:{
    ...mapActions({
      openModal: "modal/modal-main/openModal",
    }),
    modal(item){
      let payload = {
        modal_component: 'modal-advantages',
        modal_title: item.text,
        modal_sub_title: item.description
      }
      this.openModal(payload)
    }
  }
}
