
import {mapActions, mapGetters, mapMutations} from 'vuex';
import banks from "@/apollo/queries/bank/banks";
export default {
  computed:{
    ...mapGetters({
      banks: 'banks/banks',
    }),
  },
  methods: {
    ...mapMutations({
      setBanks: 'banks/SET_BANKS'
    }),
    ...mapActions({
      request: 'filters/filters/request',
    }),
  },
  async fetch() {
    let response = await this.request({ query: banks, variables: {} })
    if (this.$route.path != '/banks') {
      response.data.banks = response.data.banks.splice(0, 6)
    }
    await this.setBanks(response.data.banks)
  }
}
